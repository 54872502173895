.leading__container {
    display: flex;
    flex-direction: row;
    padding-top: 140px;
}

.leading__header {

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    color: #4C4F70;
}

.leading__text_container {
    display: flex;
    flex-direction: column;

}

.leading__name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 115px;
    margin-bottom: 18px;
}

.leading__name h2 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    color: #000000;
    margin: 0;
}

.leading__icons {
    margin: 4px 0 0 15px;

}

.leading__icons img {
    margin: 0 9px;
}

.leading__about {
    max-width: 380px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #2F4152;
}

.leading__description {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #2F4152;
}

.leading__person {
    margin-left: 96px;
}

@media (max-width: 425px) {
    .leading__container, .leading__name {
        flex-direction: column;
    }

    .leading__header, .leading__description, .leading__about {
        text-align: center;
    }

    .leading__person {
        display: flex;
        margin: 42px 0 0 0;
        justify-content: center;
    }

    .leading__person img {
        width: 100%;
        height: unset;
    }

    .leading__container {
        padding: 50px 30px 0 30px;
    }
}