.timetable__container {
    padding-top: 138px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.timetable__header {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    color: #4C4F70;
}

.timetable__content {

}

.timetable__card {
    background: #F6F7FA;
    padding: 36px 48px 48px 48px;
    max-width: 790px;
    margin-bottom: 30px;
}
.timetable__card p{
    margin-bottom: 24px;
}

.date {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.date img {
    margin: 0 26px;
}

.date h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    margin-left: 10px;
    color: #3169FE !important;
}

.timetable__card h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 140%;
    color: #000000;
}

.timetable__blueButton, .timetable__RedButton {
    background: #3169FE;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    border: none;
}
.timetable__RedButton{
    background: #FF6B61;
}

@media (max-width: 425px){
    .timetable__header{
        display: flex;
        justify-content: center;
        text-align: center;

    }
    .timetable__container{
        flex-direction: column;
    }
    .date{
        align-items: flex-start;
        flex-direction: column;
    }
    .date h3
    {
        margin: 0;
    }
    .date img{

        margin: 0 0 18px 0;
        width: 140px;
        height: 98px;
    }
    .timetable__card{
        padding: 36px 36px 48px 36px;
    }
}