.checklist__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 49px 0;
}
.checklist__header{
    display: flex;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
}
.checklist__header img{
    margin-right: 21px;
}
.checklist__text{
    margin-top: 10px;
    max-width: 974px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;

    color: #FFFFFF;
}
.checklist__button{
    margin-top: 22px;
    background: #F6F7FA;
    border-radius: 30px;
    width: 225px;
    height: 48px;

    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    border: none;
    color: #3169FE;
}
@media (max-width: 425px){
    .checklist__header{
        text-align: left;
        margin: 0 0 23px 27px;
    }
    .checklist__container{
        padding: 48px 30px;
    }
}