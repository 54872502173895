a{
    text-decoration: none;
}
.footer__container{
    padding-top: 56px;
    padding-bottom: 59px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer__container img{
    width: 126px;
    height: 60px;
}
.footer__address, .footer__contacts{
    display: flex;
    flex-direction: column;
}
.footer__address p, .footer__contacts p{
    margin: 0 0 13px 0;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.footer__contacts p{
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
}
.footer__contacts_icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.footer__contacts_icons img{
    width: 25px;
    height: 25px;
    margin-left: 14px;
}
@media (max-width: 425px){
    .footer__container{
        flex-direction: column;
        align-items: flex-start;
        padding: 56px 30px 59px 30px;
    }
    .footer__contacts_icons{
        justify-content: flex-start;

    }
    .footer__contacts_icons img{
        margin: 0 14px 0 0;
    }
    .footer__address{
        margin-top: 31px;
    }
    .footer__contacts{
        margin-top: 48px;
    }
    .footer__contacts p{
        margin-bottom: 47px;
    }
}