.wrapper{
    width: 1200px;
    margin: 0 auto;
}
.navbar__background{
    background: #ffffff;
}
.main__background{
    background: linear-gradient(to right, #F6F7FA 50%, #3169FE 50%);
}
.leading__background{
    background: linear-gradient(108.08deg, #D4D5D9 22.07%, #DCDDE0 52.81%, #BDBFC6 84.2%);
}
.checklist__background{
    background: #3169FE;
}
.yararMed__background{
    background: #F6F7FA;
}
.formPage__background{
    background: linear-gradient(108.08deg, #D4D5D9 22.07%, #DCDDE0 52.81%, #BDBFC6 84.2%);
}
.footer__background{
    background: #3169FE;
}
.under-footer{
    background: #F6F7FA;
}
.under-footer__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.under-footer__container p{
    margin: 4px 0;
}

@media (max-width: 425px){
    .wrapper{
        width: 410px;
    }
    .main__background{
        background: #3169FE;
    }
    .under-footer{
        padding-bottom: 16px;
        padding-top: 9px;
    }
    .under-footer__container{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 375px){
    .wrapper{
        width: 360px;

    }
}
@media (max-width: 320px){

}