.main__content_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.main__leftSide_content{
    display: flex;
    flex-direction: column;
    background: #F6F7FA;
    padding: 60px 50px 60px 0;
}
.main__leftSide_content img{
    margin-bottom: 180px;
}
.menu{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1em;
    color: #000000;
    line-height: 100%;
    text-decoration: none;
    margin: 30px 0;
}
.main__rightSide_content{
    display: flex;
    max-width: 995px;
    flex-direction: column;
    background: #3169FE;
    padding: 78px 0 106px 64px;
}
.main__header{
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
    background: #F6F7FA;
    max-width: 310px;
    height: 39px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 130%;
    color: #4C4F70;
}
.date{
    display: flex;
    margin-top: 115px;
    flex-direction: row;
    justify-content: space-between;
}
.date p{
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;

    color: #FFFFFF;
}
.date span{
    font-weight: bold;
}
.main__main_header{
    display: flex;
    flex-direction: row;
}
.main__main_header h1{
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 120%;
    margin-right: 46px;
    color: #FFFFFF;
}
.main__description{
    margin-top: 63px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    /* or 32px */


    color: #FFFFFF;
}
.main__checkpoints{
    display: flex;
    flex-direction: row;
    margin-top: 46px;
    justify-content: space-between;
}
.main__checkpoints p{
    margin-top: 0;
    margin-left: 10px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}
.main__checkpoint{
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    margin-right: 36px;
}
.main__button{
    background: #FF6B61;
    border-radius: 30px;
    padding: 16px 30px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    width: 233px;
    border: none;
    color: #FFFFFF;
    margin-top: 72px;

}

@media (max-width: 425px){
    .main__header{
        padding: 5px 5px;
    }
    .main__main_header h1{
        margin-right: 0;
    }
    .main__leftSide_content{
        display: none;
    }
    .date{
        margin-top: 45px;
        flex-direction: column;
    }
    .main__content_container{
        flex-direction: column;
        align-items: center;
    }
    .main__main_header{
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .main__main_header img{
        width: 200px;
        height: 165px;
    }
    .main__rightSide_content{
        align-items: center;
        padding: 50px 0 63px 0;
    }
    .main__description{
        font-size: 18px;
        text-align: center;
    }
    .main__checkpoints{
        flex-direction: column;
    }
}