.forWho__container{
    padding-top: 100px;
}
.forWho__header{
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    color: #4C4F70;
}
.forWho__content_container, .forWho__card{
    display: flex;
    flex-direction: row;
}
.forWho__content_container{
    margin-top: 70px;
}
.forWho__card{
    max-width: 380px;
    height: auto;
    align-items: flex-start;
}
.forWho__card img{
    margin-right: 25px;
}
.forWho__card p{
    margin: 0;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */


    color: #2F4152;
}

@media (max-width: 425px){
    .forWho__card{
        margin-bottom: 30px;
    }
    .forWho__content_container{
        flex-direction: column ;
    }
    .forWho__header{
        text-align: center;
    }
    .forWho__container{
        padding: 81px 30px 0 30px;
    }
}
