button {
    cursor: pointer;
}

a {
    cursor: pointer;
}

.menu_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar_container {

    display: none;
}

.navbar {
    background: #ffffff;
    z-index: 10;
    top: 0;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
}

.sticky {

    padding: 13.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    background: #ffffff;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
}

.menu {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1em;
    color: #000000;
    line-height: 100%;
    text-decoration: none;
    margin: 0 20px;
}

.navbar_button button, .burger_button button {
    background: #FF6B61;
    border-radius: 30px;
    max-width: 213px;
    height: 40px;
    padding: 11px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    border: none;
}
.navbar_hamburger{
    display: none;
    background: url('/src/img/hamburger-icon.svg') no-repeat;
    width: 26px;
    height: 26px;
    outline: none;
    border: none;
}
.burger_menu_container{
    display: none;
    padding: 73px 30px;
}
.burger_menu_text a{
    margin-bottom: 60px;
}
.burger_menu_text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.burger_menu_active{
    display: inherit;
    position: fixed;
    top: 70px;
    width: 100%;
    padding: 73px 30px;
    background: #E5E5E5;
}
@media (max-width: 425px) {
    .sticky{
        justify-content: space-between;
    }
    .navbar_hamburger{
        padding-right: 47px;
        display: inherit;
    }
    .navbar_close{
        padding-right: 49px;
        background: url('/src/img/close-icon.svg') no-repeat;
        width: 26px;
        height: 26px;
        outline: none;
        border: none;
    }
    .menu_container{
        display: none;
    }
    .navbar_button{
        display: none;
    }
    #logo{
        width: 94.5px;
        height: 45px;
    }
}