.projects__container{
    padding-top: 99px;
    padding-bottom: 111px;
}
.projects__header{
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    text-align: center;

    color: #4C4F70;
}
.projects__pics_container{
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.projects__company_pic{
    margin-top: 41px;
    width: 248px;
    height: 106px;
}

@media (max-width: 425px){

    .projects__pics_container{
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
}