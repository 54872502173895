.formPage__container {
    padding-top: 93px;
    padding-bottom: 104px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.formPage__text_block {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.formPage__text_header {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    color: #4C4F70;
    text-align: center;
}

.formPage__text {
    margin-top: 28px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #000000;
    text-align: center;
}

button {
    background: #FF6B61;
    border-radius: 30px;
    margin-top: 25px;
    width: 233px;
    height: 48px;
    border: none;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
}
iframe{
    height: 100% !important;
}
@media (max-width: 425px) {
    .formPage__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 71px 30px 62px 30px;
    }

    .formPage__text, .formPage__text_header {
        text-align: center;
    }

    .formPage__form_block input {
        width: 100%;
    }

    .formPage__form_block form {
        align-items: center;
        margin-top: 46px;
    }
}

/* form */

/*.sp-force-hide {
    display: none;
}

.sp-form[sp-id="200384"] {
    z-index: 1;
    display: block;
    padding: 15px;
    width: 570px;
    max-width: 100%;
    border-radius: 15px;
    font-family: Tahoma, Geneva, sans-serif;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}

.sp-form[sp-id="200384"] input[type="checkbox"] {
    display: inline-block;
    opacity: 1;
    visibility: visible;
}

.sp-form[sp-id="200384"] .sp-form-fields-wrapper {
    margin: 0 auto;
    width: 540px;
}
@media (max-width: 425px) {
    .sp-form[sp-id="200384"] .sp-form-fields-wrapper{
        width: 410px;
    }
}
@media (max-width: 375px){
    .sp-form[sp-id="200384"] .sp-form-fields-wrapper{
        width: 360px;

    }
}*/
/*
.sp-form[sp-id="200384"] .sp-form-control {
    background: #ffffff;
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    font-size: 15px;
    padding-left: 8.75px;
    padding-right: 8.75px;
    border-radius: 25px;
    height: 35px;
    width: 100%;
}

.sp-form[sp-id="200384"] .sp-field label {
    color: #444444;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
}

.sp-form[sp-id="200384"] .sp-button-messengers {
    border-radius: 25px;
}

.sp-form[sp-id="200384"] .sp-button {
    border-radius: 25px;
    background-color: #ff6b61;
    color: #ffffff;
    width: auto;
    font-weight: 700;
    font-style: normal;
    font-family: Tahoma, Geneva, sans-serif;
    box-shadow: none;
}

.sp-form[sp-id="200384"] .sp-button-container {
    text-align: left;
}*/
