.yararMed__container {
    padding-top: 93px;
    padding-bottom: 73px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.yararMed__head_text {
    max-width: 790px;
}

.yararMed__head_text h2 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #4C4F70;
}

.yararMed__head_text p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #2F4152;
}

.yararMed__content_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.yararMed__content_card, .yararMed__content_width_card {
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: left;
    max-width: 308px;
    height: auto;
    background: #ffffff;
    margin-right: 30px;
    margin-bottom: 30px;
    border: 1px solid #9BA9B4;
}

.yararMed__content_card img, .yararMed__content_width_card img {
    width: 59px;
    height: 60px;
}

.yararMed__content_card h4, .yararMed__content_width_card h4 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 130%;
    color: #000000;
    margin: 18px 0;
}
.yararMed__content_card p, .yararMed__content_width_card p{
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #2F4152;
}
.room_img{
    width: 339px;
    height: 330px;
    margin-left: 62px;
}
.yararMed__content_width_card{
    max-width: 59%;
    margin: 0;
}
.yararMed__button{
    margin-top: 76px;
    width: 196px;
    height: 48px;
    background: #FF6B61;
    border-radius: 30px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    text-align: center;
    color: #FFFFFF;
    border: none;
}
@media (max-width: 425px){
    .yararMed__content_row{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .yararMed__content_width_card{
        max-width: 76%;
    }
    .yararMed__content_card, .yararMed__content_width_card{
        margin: 0 0 30px 0;
    }
    .room_img{
        display: none;
    }
    .yararMed__button{
        margin-top: 65px;
    }
}
